import {
  areIntervalSameDay,
  endOfDay,
  formatDate,
  getLastMonthInterval,
  getThisDayInterval,
  getThisMonthInterval,
  getYesterdayInterval,
  isSameDay,
  startOfDay,
} from "@cashbook/util-dates"
import { getWhatsAppSharingLink, queryToSearch } from "@cashbook/util-general"
import {
  AddMoneyIcon,
  WalletInIcon,
  IconProps,
  Box,
  Text,
  UPIWalletIcon,
  UPIWalletLimits,
  UPIIcon,
  DebitCardIcon,
  Circle,
  StoreSupplierIcon,
  Icon,
  useOverlayTriggerState,
  Modal,
  ModalBody,
  Stack,
  Inline,
  DatePicker,
  ModalFooter,
  Button,
  MasterWalletIcon,
  BankPlaceholderIcon,
  CheckCircleIcon,
  InformationCircleIcon,
  CancelCircleIcon,
  ArrowLeftIcon,
  InformationCircleFilledIcon,
  DateSelect,
  MoneyTransferIcon,
  POSIcon,
  LaptopIcon,
  GradientCardIcon,
  Tooltip,
  ATMIcon,
  TapAndPayIcon,
  GetNotifiedExpenses,
} from "@cashbook/web-components"
import { getColorForString } from "generate-colors"
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useSyncExternalStore,
} from "react"
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom"
import Lottie from "lottie-react"
import NotFetchedAnimation from "./not_fetched.json"
import { useRemoteConfigNumber } from "reactfire"
import config from "../config"
import { getCurrentBrowser, getDeviceOS } from "@cashbook/device-info"
import { $PropertyType } from "utility-types"
import { showPaymentsStore } from "@cashbook/data-store/storage"
import { PaymentTransactionTypes } from "@cashbook/data-store/payments"

export * from "./Transactions"

export type PAYMENTS_ICON_NAMES =
  | "addMoney"
  | "walletIn"
  | "upiWallet"
  | "upiWalletLimits"
  | "upi"
  | "card"
  | "masterWallet"
  | "bank"
  | "checkCircle"
  | "iconWarning"
  | "cancelCircle"
  | "moneyTransfer"
  | "pos"
  | "ecom"
  | "master_wallet"
  | "gradientCard"
  | "atm"
  | "tap&pay"
  | "notifiedExpenses"

type PAYMENTS_ICON_TYPES = {
  name: PAYMENTS_ICON_NAMES
}
export function PaymentsIcons({
  name,
  ...props
}: PAYMENTS_ICON_TYPES & IconProps) {
  switch (name) {
    case "addMoney":
      return <AddMoneyIcon {...props} />
    case "walletIn":
      return <WalletInIcon {...props} />
    case "upiWallet":
      return <UPIWalletIcon {...props} />
    case "upiWalletLimits":
      return <UPIWalletLimits {...props} />
    case "upi":
      return <UPIIcon {...props} />
    case "card":
      return (
        <Tooltip event="onHover" content={`${config.appTitle} Wallet`}>
          <DebitCardIcon {...props} />
        </Tooltip>
      )
    case "masterWallet":
    case "master_wallet":
      return <MasterWalletIcon {...props} />
    case "bank":
      return <BankPlaceholderIcon {...props} />
    case "cancelCircle":
      return <CancelCircleIcon {...props} />
    case "iconWarning":
      return <InformationCircleIcon {...props} />
    case "checkCircle":
      return <CheckCircleIcon {...props} />
    case "moneyTransfer":
      return <MoneyTransferIcon {...props} />
    case "pos":
      return (
        <Tooltip event="onHover" content="Merchant Outlet">
          <POSIcon {...props} />
        </Tooltip>
      )
    case "ecom":
      return (
        <Tooltip event="onHover" content="Online">
          <LaptopIcon {...props} />
        </Tooltip>
      )
    case "gradientCard":
      return <GradientCardIcon {...props} />
    case "atm":
      return (
        <Tooltip event="onHover" content="ATM Outlet">
          <ATMIcon {...props} />
        </Tooltip>
      )
    case "tap&pay":
      return (
        <Tooltip event="onHover" content="Tap & Pay">
          <TapAndPayIcon {...props} />
        </Tooltip>
      )
    case "notifiedExpenses":
      return <GetNotifiedExpenses {...props} />
    default:
      return null
  }
}

type AVATAR = {
  id: string
  name?: string
  size?: React.ComponentProps<typeof Circle>["size"]
  color?: React.ComponentProps<typeof Box>["color"]
  fontSize?: React.ComponentProps<typeof Text>["fontSize"]
  type?: "individual" | "merchant"
  iconSize?: React.ComponentProps<typeof Icon>["size"]
}

export function Avatar({
  id,
  name,
  size,
  type,
  fontSize,
  iconSize,
  color,
}: AVATAR) {
  const [r, g, b] = getColorForString(id || name || "cb")
  return (
    <Circle
      size={size || "14"}
      style={{
        color: `rgb(${r}, ${g}, ${b})`,
        background: `rgba(${r}, ${g}, ${b}, .7)`,
      }}
    >
      {type === "merchant" ? (
        <Box>
          <StoreSupplierIcon
            color={color || "iconOnSurface"}
            size={iconSize || "4"}
          />
        </Box>
      ) : (
        <Text
          textTransform="uppercase"
          color={color || "textOnSurface"}
          fontSize={fontSize || "xl"}
        >
          {name ? name.charAt(0) : "CB"}
        </Text>
      )}
    </Circle>
  )
}

export function getTransactionModeOfPayment(
  type?: PaymentTransactionTypes
): "upi" | "master_wallet" {
  switch (type) {
    case "UPI_COLLECT":
    case "UPI_COLLECT_CREDIT":
    case "UPI_CREDIT":
    case "UPI_DEBIT":
    case "UPI_REFUND":
      return "upi"
    case "B2B":
    case "B2C":
    case "VIRTUAL_ACCOUNT_CREDIT":
    case "C2B":
    default:
      return "master_wallet"
  }
}

const options_for_custom_date = [
  { id: "dateRange", label: "Date Range" },
  { id: "singleDay", label: "Single Day" },
]
export function SelectCustomDate({
  wasCustomApplied,
  appliedCustomFilterType,
  value: [afterDate, beforeDate],
  onChange,
  children,
}: {
  wasCustomApplied?: boolean
  appliedCustomFilterType?: "dateRange" | "singleDay"
  value: [Date | undefined, Date | undefined]
  onChange: (dates: [Date | undefined, Date | undefined], label: string) => void
  children: (props: { onSelect: () => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({})
  const [localeAfterDate, setAfterDate] = useState<Date | undefined>(undefined)
  const [localeBeforeDate, setBeforeDate] = useState<Date | undefined>(
    undefined
  )
  const [dateValidationError, setDateValidationError] = useState<
    { startDate?: string; endDate?: string } | undefined
  >(undefined)

  const [selectedOption, setSelectedOption] = useState<string | undefined>(
    appliedCustomFilterType
  )

  useEffect(() => {
    if (wasCustomApplied) {
      setAfterDate(afterDate)
      setBeforeDate(beforeDate)
    } else {
      setAfterDate(undefined)
      setBeforeDate(undefined)
      setDateValidationError(undefined)
      setSelectedOption("dateRange")
    }
  }, [afterDate, beforeDate, wasCustomApplied])

  const onClose = () => {
    if (!wasCustomApplied) {
      setAfterDate(undefined)
      setBeforeDate(undefined)
    }
    setSelectedOption(appliedCustomFilterType)
    setDateValidationError(undefined)
    state.close()
  }

  const onSave = () => {
    if (selectedOption === "singleDay") {
      if (!localeAfterDate) {
        setDateValidationError({ startDate: "Please select a valid date!" })
        return
      }
      onChange([localeAfterDate, localeBeforeDate], "single_day")
    } else {
      if (!localeAfterDate || !localeBeforeDate) {
        let error = {}
        if (!localeAfterDate) {
          error = { ...error, startDate: "Select start date" }
        }
        if (!localeBeforeDate) {
          error = { ...error, endDate: "Select end date" }
        }
        setDateValidationError(error)
        return
      }
      onChange([localeAfterDate, localeBeforeDate], "date_range")
    }
    setDateValidationError(undefined)
    state.close()
  }

  return (
    <>
      {children({ onSelect: state.open })}
      <Modal
        title="Select Custom Date"
        isOpen={state.isOpen}
        onClose={onClose}
        size="sm"
        isDismissable
      >
        <ModalBody autoMaxHeight>
          <Stack gap="8">
            <Inline gap="4" as="ul">
              {options_for_custom_date.map((option) => (
                <Box
                  as="li"
                  key={option.id}
                  borderColor="borderOutline"
                  paddingY="2"
                  paddingX="4"
                  borderWidth={selectedOption === option.id ? undefined : "1"}
                  rounded="md"
                  cursor="pointer"
                  backgroundColor={
                    selectedOption === option.id
                      ? "surfacePrimaryLowest"
                      : undefined
                  }
                  onClick={() => {
                    setDateValidationError(undefined)
                    setSelectedOption(option.id)
                  }}
                >
                  <Text
                    fontSize="b3"
                    color={
                      selectedOption === option.id ? "textPrimary" : undefined
                    }
                  >
                    {option.label}
                  </Text>
                </Box>
              ))}
            </Inline>
            {selectedOption === "singleDay" ? (
              <Stack flex="1" gap="1">
                <Text fontSize="c2">Date</Text>
                <DatePicker
                  value={localeAfterDate}
                  placeholder="DD MM YYYY"
                  onChange={(date) => {
                    setDateValidationError(undefined)
                    setAfterDate(date ? startOfDay(date) : undefined)
                    setBeforeDate(date ? endOfDay(date) : undefined)
                  }}
                  inputProps={{
                    error: dateValidationError?.startDate,
                    className:
                      "text-[16px] font-medium placeholder:font-medium placeholder:text-[16px] placeholder:text-[#9E9E9E]",
                  }}
                />
              </Stack>
            ) : (
              <Inline gap="8">
                <Stack flex="1" gap="1">
                  <Text fontSize="c2">Start Date</Text>
                  <DatePicker
                    placeholder="DD MM YYYY"
                    value={localeAfterDate}
                    onChange={(date) => {
                      setDateValidationError((prevState) => {
                        return { ...prevState, startDate: undefined }
                      })
                      setAfterDate(date ? startOfDay(date) : undefined)
                    }}
                    max={localeBeforeDate}
                    inputProps={{
                      error: dateValidationError?.startDate,
                      className:
                        "text-[16px] font-medium placeholder:font-medium placeholder:text-[16px] placeholder:text-[#9E9E9E]",
                    }}
                  />
                </Stack>
                <Stack flex="1" gap="1">
                  <Text fontSize="c2">End Date</Text>
                  <DatePicker
                    value={localeBeforeDate}
                    onChange={(date) => {
                      setDateValidationError((prevState) => {
                        return { ...prevState, endDate: undefined }
                      })
                      setBeforeDate(date ? endOfDay(date) : undefined)
                    }}
                    min={localeAfterDate}
                    inputProps={{
                      error: dateValidationError?.endDate,
                      className:
                        "text-[16px] font-medium placeholder:font-medium placeholder:text-[16px] placeholder:text-[#9E9E9E]",
                    }}
                  />
                </Stack>
              </Inline>
            )}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onSave} level="primary" size="lg">
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export function DateSelectForPayments({
  height,
  from_datetime,
  to_datetime,
  onChange,
}: {
  from_datetime?: Date
  to_datetime?: Date
  height?: React.ComponentProps<typeof Box>["height"]
  onChange?: (from?: Date, to?: Date, label?: string) => void
}) {
  const resolvedDateIntervals = useMemo(
    () => resolveAppliedDateInterval([from_datetime, to_datetime]),
    [from_datetime, to_datetime]
  )

  const optionsForDateFilter = useMemo(() => {
    return [
      {
        id: "all",
        label: "All Time",
        value: [undefined, undefined] as [undefined | Date, undefined | Date],
      },
    ]
      .concat(
        resolvedDateIntervals.intervalsWithAppliedStatus.map((i) => ({
          id: i.label,
          label: i.label,
          value: [i.interval[0], i.interval[1]],
        }))
      )
      .concat([
        {
          id: "custom",
          label:
            resolvedDateIntervals.appliedDateIntervalLabel === "Custom" &&
            from_datetime &&
            to_datetime
              ? isSameDay(from_datetime, to_datetime)
                ? `${formatDate(from_datetime, "dd MMM yy")}`
                : `${
                    from_datetime
                      ? formatDate(from_datetime, "dd MMM yy")
                      : "Start"
                  } - ${
                    to_datetime ? formatDate(to_datetime, "dd MMM yy") : "Now"
                  }`
              : "Custom",
          value: [undefined, undefined],
        },
      ])
  }, [
    from_datetime,
    resolvedDateIntervals.appliedDateIntervalLabel,
    resolvedDateIntervals.intervalsWithAppliedStatus,
    to_datetime,
  ])

  const customDateFilterType: "dateRange" | "singleDay" = useMemo(() => {
    if (resolvedDateIntervals.customDateApplied) {
      if (from_datetime && to_datetime) {
        if (isSameDay(from_datetime, to_datetime)) {
          return "singleDay"
        } else {
          return "dateRange"
        }
      } else if (from_datetime) {
        return "singleDay"
      } else {
        return "dateRange"
      }
    }
    return "dateRange"
  }, [from_datetime, to_datetime, resolvedDateIntervals.customDateApplied])

  const selectedDateFilter = useMemo(() => {
    if (resolvedDateIntervals.customDateApplied) {
      return {
        id: "custom",
        label:
          resolvedDateIntervals.appliedDateIntervalLabel === "Custom" &&
          from_datetime &&
          to_datetime
            ? isSameDay(from_datetime, to_datetime)
              ? `${formatDate(from_datetime, "dd MMM yy")}`
              : `${
                  from_datetime
                    ? formatDate(from_datetime, "dd MMM yy")
                    : "Start"
                } - ${
                  to_datetime ? formatDate(to_datetime, "dd MMM yy") : "Now"
                }`
            : "Custom",
        value: [from_datetime, to_datetime],
      }
    }
    const selectedOption = optionsForDateFilter.find(
      (option) =>
        option.label === resolvedDateIntervals.appliedDateIntervalLabel
    )
    return selectedOption
  }, [
    from_datetime,
    to_datetime,
    optionsForDateFilter,
    resolvedDateIntervals.appliedDateIntervalLabel,
    resolvedDateIntervals.customDateApplied,
  ])

  const isDateFilterApplied: boolean = useMemo(() => {
    return !resolvedDateIntervals.allTimeApplied
  }, [resolvedDateIntervals.allTimeApplied])

  const appliedDateFilterLabel: string = useMemo(() => {
    return resolvedDateIntervals.allTimeApplied
      ? "Duration: All Time"
      : resolvedDateIntervals.customDateApplied
      ? from_datetime && to_datetime && isSameDay(from_datetime, to_datetime)
        ? `${formatDate(from_datetime, "dd MMM yy")}`
        : `${
            from_datetime ? formatDate(from_datetime, "dd MMM yy") : "Start"
          } - ${to_datetime ? formatDate(to_datetime, "dd MMM yy") : "Now"}`
      : `${resolvedDateIntervals.appliedDateIntervalLabel}`
  }, [
    from_datetime,
    resolvedDateIntervals.allTimeApplied,
    resolvedDateIntervals.appliedDateIntervalLabel,
    resolvedDateIntervals.customDateApplied,
    to_datetime,
  ])

  return (
    <SelectCustomDate
      value={[from_datetime, to_datetime]}
      appliedCustomFilterType={customDateFilterType}
      wasCustomApplied={resolvedDateIntervals.customDateApplied}
      onChange={([from_datetime, to_datetime], label) => {
        onChange?.(from_datetime, to_datetime, label)
      }}
    >
      {({ onSelect }) => (
        <DateSelect
          removeOpeningBalance
          value={selectedDateFilter}
          options={optionsForDateFilter}
          hasValue={isDateFilterApplied}
          onChange={(option) => {
            if (option?.id === "custom") {
              return onSelect()
            }
            onChange?.(option?.value[0], option?.value[1], option?.id)
          }}
          height={height}
          label={appliedDateFilterLabel}
        />
      )}
    </SelectCustomDate>
  )
}

function getDateIntervals(relativeTo?: Date) {
  return [
    { interval: getThisDayInterval(relativeTo), label: "Today" as const },
    { interval: getYesterdayInterval(relativeTo), label: "Yesterday" as const },
    {
      interval: getThisMonthInterval(relativeTo),
      label: "This Month" as const,
    },
    {
      interval: getLastMonthInterval(relativeTo),
      label: "Last Month" as const,
    },
  ]
}

function resolveAppliedDateInterval(
  appliedInterval: [afterDate: Date | undefined, beforeDate: Date | undefined]
) {
  const intervals = getDateIntervals(new Date())
  const [afterDate, beforeDate] = appliedInterval
  let allTimeApplied = true
  let customDateApplied = false
  let label:
    | $PropertyType<ReturnType<typeof getDateIntervals>[number], "label">
    | "All Time"
    | "Custom" = "All Time"
  let dateFilterAppliedType:
    | $PropertyType<ReturnType<typeof getDateIntervals>[number], "label">
    | "All Time"
    | "Single Day"
    | "Date Range" = "All Time"
  // NOTE: We will mutate this object's isApplied status
  const intervalsWithAppliedStatus = intervals.map((i) => ({
    ...i,
    isApplied: false,
  }))
  if (afterDate || beforeDate) {
    allTimeApplied = false
    customDateApplied = true
    label = "Custom"

    if (afterDate && beforeDate && isSameDay(afterDate, beforeDate)) {
      dateFilterAppliedType = "Single Day"
    } else {
      dateFilterAppliedType = "Date Range"
    }
    for (const interval of intervalsWithAppliedStatus) {
      const isThisIntervalApplied =
        afterDate && beforeDate
          ? areIntervalSameDay([afterDate, beforeDate], interval.interval)
          : false
      if (isThisIntervalApplied) {
        label = interval.label
        dateFilterAppliedType = interval.label
        customDateApplied = false
        // NOTE: we are mutating the object here
        interval.isApplied = true
      }
    }
  }
  return {
    intervalsWithAppliedStatus,
    customDateApplied,
    allTimeApplied,
    appliedDateIntervalLabel: label,
    dateFilterAppliedType,
  }
}

export function PaymentsProtectedRoutes({
  redirectTo = "/",
  children,
  redirectBack,
}: {
  redirectTo?: string
  children?: React.ReactNode
  redirectBack?: boolean
}) {
  const location = useLocation()
  const { businessId } = useParams()
  const showPayments = useSyncExternalStore(
    showPaymentsStore.subscribe,
    showPaymentsStore.getShowPaymentsList
  )
  if (!businessId || !showPayments[businessId]) {
    return (
      <Navigate
        to={`${redirectTo}${queryToSearch(
          redirectBack
            ? {
                next: location.pathname + location.search,
              }
            : {}
        )}`}
        replace
      />
    )
  }
  return <>{children}</>
}

export function BreadCrumbsForPayments({
  links,
  title,
  position,
  removeBorderLine,
}: {
  title: string
  removeBorderLine?: boolean
  position?: React.ComponentProps<typeof Box>["position"]
  links: Array<{ title: string; to: string; header?: string }>
}) {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const [active] = useState<string>(links[links.length - 1].to)

  const resolve = useResolvedValidNavigationLink(pathname)

  function onLinkClick(to: string, header?: string) {
    const resolvedLink = resolve(to, header)
    navigate(resolvedLink)
  }

  return (
    <Box
      paddingY="6"
      paddingX="8"
      position={position || "sticky"}
      top="0"
      backgroundColor="backgroundLight1"
      borderBottomWidth={removeBorderLine ? undefined : "1"}
      borderColor="borderDividers"
      className="z-[1]"
    >
      <Stack gap="6">
        <Inline as="ul" gap="1">
          {links.map((link, i) => {
            return (
              <Inline key={link.to} gap="1" fontSize="c2">
                <Text
                  as="button"
                  onClick={() => onLinkClick(link.to, link.header)}
                  disabled={active === link.to}
                  textTransform="capitalize"
                  color={active === link.to ? "textMedium" : "textPrimary"}
                >
                  {link.title}
                </Text>
                <Text color="textMedium">
                  {i === links.length - 1 ? "" : ">"}
                </Text>
              </Inline>
            )
          })}
        </Inline>
        <Inline alignItems="center" gap="4">
          <Button onClick={() => navigate(-1)} inline>
            <Box>
              <ArrowLeftIcon color="iconHigh" />
            </Box>
          </Button>
          <Text textTransform="capitalize" as="h4" fontSize="s1">
            {title}
          </Text>
        </Inline>
      </Stack>
    </Box>
  )
}

function useResolvedValidNavigationLink(pathname: string) {
  return useCallback(
    (path: string, header?: string) => {
      let resolvedLink: string =
        pathname.substring(0, pathname.indexOf(path)) + path
      if (header) {
        resolvedLink = resolvedLink + `?header=${header}`
      }
      return resolvedLink
    },
    [pathname]
  )
}

const deviceOs = getDeviceOS()
const deviceBrowser = getCurrentBrowser()
export function GeneralErrorHandler({
  retryBtnText,
  onRetry,
}: {
  retryBtnText?: string
  onRetry: () => void
}) {
  const { data } = useRemoteConfigNumber(
    config.firebaseConfig.supportPhoneNumberKey
  )
  const supportPhoneNumber = data || config.supportPhoneNumber
  return (
    <Stack height="full" alignItems="center" justifyContent="center">
      <Box maxWidth="md">
        <Stack
          gap="2"
          textAlign="center"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            className="w-[152px] h-[152px]"
          >
            <Lottie animationData={NotFetchedAnimation} loop={true} />
          </Box>
          <Text fontSize="s1">Oops! Unable to fetch your data</Text>
          <Text fontSize="b3">
            Data retrieval taking longer than expected or internet connection is
            slow. Refresh the page to retry.
          </Text>
          <Box marginTop="4">
            <Button level="primary" onClick={() => onRetry?.()}>
              {retryBtnText ? retryBtnText : "Refresh the Page"}
            </Button>
          </Box>
        </Stack>
        <Stack paddingTop="8" gap="4">
          <Inline
            rounded="md"
            backgroundColor="surfaceSuccessLowest"
            color="textCashIn"
            padding="4"
            gap="4"
            justifyContent="center"
          >
            <InformationCircleFilledIcon size="5" />
            <Text fontSize="b3">
              Don’t worry, your data is completely safe with us and we are
              continuously trying to fix this issue
            </Text>
          </Inline>
          <Inline
            fontSize="c2"
            alignItems="center"
            justifyContent="center"
            gap="1"
            color="textMedium"
          >
            <Text>Issue still persist after long time?</Text>
            <Text
              as="a"
              href={`${getWhatsAppSharingLink({
                text: `Unable to fetch payments data on web App. Version: ${config.appVersion}, Browser: ${deviceBrowser}, OS: ${deviceOs}`,
                phoneNumber: supportPhoneNumber,
              })}`}
              className="underline"
              target="_blank"
            >
              Contact Us
            </Text>
          </Inline>
        </Stack>
      </Box>
    </Stack>
  )
}

export const walletLimits: {
  [key in "min_kyc" | "full_kyc" | "init"]: number
} = {
  min_kyc: 10000,
  full_kyc: 200000,
  init: 0,
}
