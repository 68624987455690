import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { parse } from "qs"
import { channels, configureChannels } from "./channels"

export function configureTracking(
  config: Parameters<typeof configureChannels>[0]
) {
  configureChannels(config)
}

export enum TrackingEvents {
  /**
   * General Events
   */
  LOGOUT = "LOGOUT",
  PHONE_SUBMITTED = "PHONE_SUBMITTED",
  EMAIL_SUBMITTED_FOR_LOGIN = "EMAIL_SUBMITTED_FOR_LOGIN",
  EMAIL_ADDED = "EMAIL_ADDED",
  LOGIN_OTP_VERIFIED = "LOGIN_OTP_VERIFIED",
  OTP_RESENT = "OTP_RESENT",
  OTP_VERIFIED_NEW_USER = "OTP_VERIFIED_NEW_USER",
  PRIVACY_POLICY_VIEWED = "PRIVACY_POLICY_VIEWED",
  T_AND_C_VIEWED = "T_AND_C_VIEWED",
  PHONE_NOT_REGISTERED = "PHONE_NOT_REGISTERED",

  ADD_NEW_BOOK = "ADD_NEW_BOOK",
  CASH_OUT_CLICKED = "CASH_OUT_CLICKED",
  CASH_IN_CLICKED = "CASH_IN_CLICKED",
  PROFILE_SCREEN_VISIT = "PROFILE_SCREEN_VISIT",
  NAME_SUBMITTED = "NAME_SUBMITTED",
  DARK_THEME_DISABLED = "DARK_THEME_DISABLED",
  DARK_THEME_ENABLED = "DARK_THEME_ENABLED",
  RENAME_CASH_BOOK = "RENAME_CASH_BOOK",

  CASH_IN_ENTRY_DONE = "CASH_IN_ENTRY_DONE",
  CASH_OUT_ENTRY_DONE = "CASH_OUT_ENTRY_DONE",
  DELETE_ENTRY = "DELETE_ENTRY",
  EDIT_ENTRY_CLICKED = "EDIT_ENTRY_CLICKED",
  EDIT_ENTRY = "EDIT_ENTRY",
  ENTRY_DONE = "ENTRY_DONE",
  DATE_PICKER_CLICKED = "DATE_PICKER_CLICKED",
  TIME_PICKER_CLICKED = "TIME_PICKER_CLICKED",
  BILL_IMAGE_UPLOAD_STARTED = "BILL_IMAGE_UPLOAD_STARTED",
  BILL_IMAGE_UPLOAD_FINISHED = "BILL_IMAGE_UPLOAD_FINISHED",
  BILL_IMAGE_DOWNLOAD_CLICKED = "BILL_IMAGE_DOWNLOAD_CLICKED",
  ATTACH_BILL_IMAGE_CLICKED = "ATTACH_BILL_IMAGE_CLICKED",
  BILL_IMAGE_REMOVED = "BILL_IMAGE_REMOVED",
  ENTRY_CLICKED = "ENTRY_CLICKED",
  ENTRY_THREE_DOTS_CLICKED = "ENTRY_THREE_DOTS_CLICKED",
  ENTRY_ACTION_CLICKED = "ENTRY_ACTION_CLICKED",
  ENTRY_ACTION_STARTED = "ENTRY_ACTION_STARTED",
  ENTRY_ACTION_COMPLETED = "ENTRY_ACTION_COMPLETED",
  ENTRY_SYNC_STATUS_CLICKED = "ENTRY_SYNC_STATUS_CLICKED",
  BOOK_DELETION_CANCELLED = "BOOK_DELETION_CANCELLED",
  BOOK_DELETED = "BOOK_DELETED",
  VERIFY_EMAIL_CLICKED = "VERIFY_EMAIL_CLICKED",

  /**
   * Members
   */
  MEMBERS_SCREEN_VIEWED = "MEMBERS_SCREEN_VIEWED",

  /**
   * Entry Fields
   */
  CATEGORIES_IMPORTED_SUCCESSFULLY = "CATEGORIES_IMPORTED_SUCCESSFULLY",
  PAYMENT_MODES_IMPORTED_SUCCESSFULLY = "PAYMENT_MODES_IMPORTED_SUCCESSFULLY",
  PARTY_SETTINGS_SCREEN_VIEWED = "PARTY_SETTINGS_SCREEN_VIEWED",
  CATEGORY_SETTINGS_SCREEN_VIEWED = "CATEGORY_SETTINGS_SCREEN_VIEWED",
  PAYMENT_MODE_SETTINGS_SCREEN_VIEWED = "PAYMENT_MODE_SETTINGS_SCREEN_VIEWED",
  CATEGORIES_REORDERED = "CATEGORIES_REORDERED",
  PAYMENT_MODES_REORDERED = "PAYMENT_MODES_REORDERED",
  CATEGORY_FIELD_ENABLED = "CATEGORY_FIELD_ENABLED",
  PAYMENT_MODE_FIELD_ENABLED = "PAYMENT_MODE_FIELD_ENABLED",
  PARTY_FIELD_ENABLED = "PARTY_MODE_FIELD_ENABLED",
  CATEGORY_FIELD_DISABLED = "CATEGORY_FIELD_DISABLED",
  PAYMENT_MODE_FIELD_DISABLED = "PAYMENT_MODE_FIELD_DISABLED",
  PARTY_FIELD_DISABLED = "PARTY_MODE_FIELD_DISABLED",
  IMPORT_CATEGORY_BUTTON_CLICKED = "IMPORT_CATEGORY_BUTTON_CLICKED",
  IMPORT_PAYMENT_MODE_BUTTON_CLICKED = "IMPORT_PAYMENT_MODE_BUTTON_CLICKED",
  IMPORT_PARTY_BUTTON_CLICKED = "IMPORT_PARTY_BUTTON_CLICKED",
  PARTIES_IMPORTED_SUCCESSFULLY = "PARTIES_IMPORTED_SUCCESSFULLY",
  CATEGORY_DELETED = "CATEGORY_DELETED",
  PAYMENT_MODE_DELETED = "PAYMENT_MODE_DELETED",
  CATEGORY_RENAMED = "CATEGORY_RENAMED",
  PAYMENT_MODE_RENAMED = "PAYMENT_MODE_RENAMED",
  CATEGORY_ADDED = "CATEGORY_ADDED",
  PAYMENT_MODE_ADDED = "PAYMENT_MODE_ADDED",
  PARTY_ADDED = "PARTY_ADDED",
  EDIT_PARTY = "EDIT_PARTY",
  PARTY_DELETED = "PARTY_DELETED",
  UPDATE_PARTY_NUMBER = "UPDATE_PARTY_NUMBER",
  ADD_PARTY_BUTTON_CLICKED = "ADD_PARTY_BUTTON_CLICKED",
  CUSTOM_FIELD_ADDED = "CUSTOM_FIELD_ADDED",
  CUSTOM_FIELD_UPDATED = "CUSTOM_FIELD_UPDATED",
  CUSTOM_FIELD_DELETED = "CUSTOM_FIELD_DELETED",
  ADD_NEW_CUSTOM_FIELD_CLICKED = "ADD_NEW_CUSTOM_FIELD_CLICKED",

  BILL_IMAGE_VIEWED = "BILL_IMAGE_VIEWED",
  GENERATE_PDF_REPORT = "GENERATE_PDF_REPORT",
  GENERATE_PDF_ERROR = "GENERATE_PDF_ERROR",
  EXCEL_DOWNLOADED = "EXCEL_DOWNLOADED",
  BOOK_SWITCHED = "BOOK_SWITCHED",

  IMPORT_ENTRIES_SCREEN_VIEWED = "IMPORT_ENTRIES_SCREEN_VIEWED",
  IMPORT_ENTRIES_FILE_SELECTED = "IMPORT_ENTRIES_FILE_SELECTED",
  IMPORT_ENTRIES_FILE_SUBMITTED = "IMPORT_ENTRIES_FILE_SUBMITTED",
  IMPORT_ENTRIES_BATCH_STARTED = "IMPORT_ENTRIES_BATCH_STARTED",
  IMPORT_ENTRIES_BATCH_FINISHED = "IMPORT_ENTRIES_BATCH_FINISHED",
  IMPORT_ENTRIES_SUCCEEDED = "IMPORT_ENTRIES_SUCCEEDED",
  IMPORT_ENTRIES_CANCELED = "IMPORT_ENTRIES_CANCELED",
  IMPORT_ENTRIES_SAMPLE_DOWNLOAD_CLICKED = "IMPORT_ENTRIES_SAMPLE_DOWNLOAD_CLICKED",

  //Parties in bulk
  IMPORT_PARTIES_CANCELED = "IMPORT_PARTIES_CANCELED",
  IMPORT_PARTIES_STARTED = "IMPORT_PARTIES_STARTED",
  IMPORT_PARTIES_SUCCEEDED = "IMPORT_PARTIES_SUCCEEDED",
  IMPORT_PARTIES_FILE_SELECTED = "IMPORT_PARTIES_FILE_SELECTED",
  IMPORT_PARTIES_FILE_SUBMITTED = "IMPORT_PARTIES_FILE_SUBMITTED",
  IMPORT_PARTIES_SCREEN_VIEWED = "IMPORT_PARTIES_SCREEN_VIEWED",
  IMPORT_PARTIES_SAMPLE_DOWNLOAD_CLICKED = "IMPORT_PARTIES_SAMPLE_DOWNLOAD_CLICKED",

  //Multiple Entry Actions
  MULTI_ENTRY_PARTY_CHANGED = "MULTI_ENTRY_PARTY_CHANGED",
  MULTI_ENTRY_CATEGORY_CHANGED = "MULTI_ENTRY_CATEGORY_CHANGED",
  MULTI_ENTRY_PAYMENT_MODE_CHANGED = "MULTI_ENTRY_PAYMENT_MODE_CHANGED",

  /**
   * Books Filtering
   */
  BOOK_SEARCH_CLICKED = "BOOK_SEARCH_CLICKED",

  /**
   * Transactions Filtering
   */
  SET_SEARCH_FOCUS = "SET_SEARCH_FOCUS",
  APPLY_FILTER = "APPLY_FILTER",
  DATE_FILTER_CLICKED = "DATE_FILTER_CLICKED",
  DATE_FILTER_APPLIED = "DATE_FILTER_APPLIED",
  CLEAR_ALL_FILTERS_CLICKED = "CLEAR_ALL_FILTERS_CLICKED",

  /**
   * Member Inivitation and Acceptence
   */
  ADD_MEMBERS_CLICKED = "ADD_MEMBERS_CLICKED",
  MEMBER_ADDED = "MEMBER_ADDED",
  MEMBER_INVITED = "MEMBER_INVITED",
  MEMBER_REMOVED = "MEMBER_REMOVED",
  ADD_CONTACT_SCREEN_VIEWED = "ADD_CONTACT_SCREEN_VIEWED",
  INVITE_SHOWN_NEW_USER = "INVITE_SHOWN_NEW_USER",
  INVITE_DIFFERENT_PHONE_REGISTER_ATTEMPT = "INVITE_DIFFERENT_PHONE_REGISTER_ATTEMPT",
  INVITE_SHOWN_ALREADY_USER = "INVITE_SHOWN_ALREADY_USER",
  INVITATION_CANCELLED = "INVITATION_CANCELLED",

  /**
   *  Member Permissions and Activities
   */
  SHARED_BOOK_LEFT = "SHARED_BOOK_LEFT",
  ROLE_CHANGED = "ROLE_CHANGED",
  DATA_OPERATOR_PERMISSION_ENABLED = "DATA_OPERATOR_PERMISSION_ENABLED",

  /**
   * Help Documentation
   */
  HELP_VIEWED = "HELP_VIEWED",
  HELP_CLICKED = "HELP_CLICKED",

  /**
   * Notifications releated
   */
  NOTIFICATION_RECEIVED = "NOTIFICATION_RECEIVED",
  NOTIFICATION_CLICKED = "NOTIFICATION_CLICKED",
  NOTIFICATION_DISMISSED = "NOTIFICATION_DISMISSED",
  NOTIFICATION_PERMISSION_REQUESTED = "NOTIFICATION_PERMISSION_REQUESTED",
  NOTIFICATION_PERMISSION_ALLOWED = "NOTIFICATION_PERMISSION_ALLOWED",
  NOTIFICATION_PERMISSION_DISMISSED = "NOTIFICATION_PERMISSION_DISMISSED",
  NOTIFICATION_APP_BLOCKED = "NOTIFICATION_APP_BLOCKED",

  /**
   * Keyboard Shortcut Usage
   */
  KEYBOARD_SHORTCUTS_SCREEN_VISIT = "KEYBOARD_SHORTCUTS_SCREEN_VISIT",
  KEYBOARD_SHORTCUT_USED = "KEYBOARD_SHORTCUT_USED",

  /**
   * App Updates
   */
  APP_UPDATE_AVAILABLE = "APP_UPDATE_AVAILABLE",
  APP_UPDATE_CLICKED = "APP_UPDATE_CLICKED",
  IOS_BANNER_CLICKED = "IOS_BANNER_CLICKED",

  /**
   * Bill Endpoint
   */
  GENERATED_BILL_VIEWED = "GENERATED_BILL_VIEWED",
  GENERATED_BILL_DOWNLOADED = "GENERATED_BILL_DOWNLOADED",
  /**
   * Account Updates
   */
  PHONE_CHANGED = "PHONE_CHANGED",
  PHONE_CHANGE_ACCOUNT_EXISTS_ERROR = "PHONE_CHANGE_ACCOUNT_EXISTS_ERROR",
  PHONE_CHANGE_SUBMITTED = "PHONE_CHANGE_SUBMITTED",

  // Web Application Install
  WEB_APP_ELIGIBLE_FOR_INSTALL = "APP_ELIGIBLE_FOR_INSTALL",
  // Fired when our custom prompt is accepted
  WEB_APP_INSTALL_PROMPT_ACCEPTED = "WEB_APP_INSTALL_PROMPT_ACCEPTED",
  // Fired when our custom prompt is rejected
  WEB_APP_INSTALL_PROMPT_REJECTED = "WEB_APP_INSTALL_PROMPT_REJECTED",
  // Fired when native install is accepted
  WEB_APP_INSTALL_NATIVE_ACCEPTED = "APP_INSTALL_NATIVE_ACCEPTED",
  // Fired when native install is rejected
  WEB_APP_INSTALL_NATIVE_REJECTED = "APP_INSTALL_NATIVE_REJECTED",
  WEB_APP_OPENED_VIA_PWA_INSTALL = "WEB_APP_OPENED_VIA_PWA_INSTALL",
  WEB_APP_OPENED_VIA_DESKTOP_INSTALL = "WEB_APP_OPENED_VIA_DESKTOP_INSTALL",

  //Duplicate Book
  DUPLICATE_BOOK_CLICKED = "DUPLICATE_BOOK_CLICKED",
  DUPLICATE_BOOK_CREATED = "DUPLICATE_BOOK_CREATED",

  //Owner-role
  OWNER_ROLE_CHANGED = "OWNER_ROLE_CHANGED",
  CHANGE_OWNER_CLICKED = "CHANGE_OWNER_CLICKED",
  OWNER_ROLE_BANNER_CLICKED = "OWNER_ROLE_BANNER_CLICKED",

  //BL Release
  BUSINESS_LEFT = "BUSINESS_LEFT",
  BUSINESS_DELETED = "BUSINESS_DELETED",
  NEW_USER_SIGN_UP = "NEW_USER_SIGN_UP",
  BUSINESS_SWITCHED = "BUSINESS_SWITCHED",
  INVITATION_SHARED = "INVITATION_SHARED",
  INVITATION_RESENT = "INVITATION_RESENT",
  NEW_BUSINESS_ADDED = "NEW_BUSINESS_ADDED",
  BUSINESS_TEAM_VIEWED = "BUSINESS_TEAM_VIEWED",
  BUSINESS_OWNER_CHANGED = "BUSINESS_OWNER_CHANGED",
  BUSINESS_TYPE_SUBMITTED = "BUSINESS_TYPE_SUBMITTED",
  BUSINESS_PROFILE_VIEWED = "BUSINESS_PROFILE_VIEWED",
  BUSINESS_SETTINGS_VIEWED = "BUSINESS_SETTINGS_VIEWED",
  USER_PROFILE_NAME_UPDATED = "USER_PROFILE_NAME_UPDATED",
  BUSINESS_CATEGORY_SUBMITTED = "BUSINESS_CATEGORY_SUBMITTED",
  ADD_EXTERNAL_MEMBER_CLICKED = "ADD_EXTERNAL_MEMBER_CLICKED",
  ADD_MEMBER_TO_BOOKS_CLICKED = "ADD_MEMBER_TO_BOOKS_CLICKED",
  BUSINESS_TEAM_MEMBER_REMOVED = "BUSINESS_TEAM_MEMBER_REMOVED",
  ADDED_MEMBER_TO_MULTIPLE_BOOKS = "ADDED_MEMBER_TO_MULTIPLE_BOOKS",
  BUSINESS_ONBOARDING_NEXT_CLICKED = "BUSINESS_ONBOARDING_NEXT_CLICKED",
  BUSINESS_TEAM_MEMBER_ROLE_CHANGED = "BUSINESS_TEAM_MEMBER_ROLE_CHANGED",
  BUSINESS_TEAM_MEMBER_PROFILE_VIEWED = "BUSINESS_TEAM_MEMBER_PROFILE_VIEWED",
  NEED_HELP_IN_BUSINESS_SETUP_CLICKED = "NEED_HELP_IN_BUSINESS_SETUP_CLICKED",
  BUSINESS_TEAM_MEMBER_ADDED_OR_INVITED = "BUSINESS_TEAM_MEMBER_ADDED_OR_INVITED",
  BUSINESS_ONBOARDING_GET_STARTED_CLICKED = "BUSINESS_ONBOARDING_GET_STARTED_CLICKED",

  CASHBOOK_PAYMENTS_BANNER_CLICKED = "CASHBOOK_PAYMENTS_BANNER_CLICKED",

  //Payments
  ADD_MONEY_CLICKED = "ADD_MONEY_CLICKED",
  ISSUE_WALLET_CLICKED = "ISSUE_WALLET_CLICKED",
  PAYMENTS_TAB_SWITCHED = "PAYMENTS_TAB_SWITCHED",
  PAYMENTS_FILTER_APPLIED = "PAYMENTS_FILTER_APPLIED",
  PAYMENTS_REPORT_DOWNLOADED = "PAYMENTS_REPORT_DOWNLOADED",
  PASSBOOK_INTRODUCTION_BANNER_CLICKED = "PASSBOOK_INTRODUCTION_BANNER_CLICKED",
  PAYMENTS_COPY_TO_CASHBOOK_INITIATED = "PAYMENTS_COPY_TO_CASHBOOK_INITIATED",
  PAYMENTS_COPY_STARTED = "PAYMENTS_COPY_STARTED",
  PAYMENTS_COPY_COMPLETED = "PAYMENTS_COPY_COMPLETED",
  WALLET_ISSUED = "WALLET_ISSUED",
  LIMITS_CHANGED = "LIMITS_CHANGED",

  // New Authentications
  SOCIAL_AUTHENTICATION_DONE = "SOCIAL_AUTHENTICATION_DONE",
  MEMBER_EMAIL_VERIFICATION_COMPLETED = "MEMBER_EMAIL_VERIFICATION_COMPLETED",

  // GOOGLE ADS EVENTS
  SIGNUP = "signup",
  CONVERSION = "conversion",
}

type T_BOOK_MEMBER_ROLE = "owner" | "partner" | "admin" | "editor" | "viewer"
type T_BUSINESS_ROLE = "owner" | "partner" | "staff"
type T_PARTY_TYPES_AVAILABLE = "customer" | "supplier"
type VERIFICATION_METHODS = "phone" | "email" | "whatsapp"

//Payments
type PAYMENTS_TAB_IDS =
  | "dashboard"
  | "allTransactions"
  | "masterWallet"
  | "staffWallets"

export function trackEvent(
  event:
    | TrackingEvents.CASH_OUT_CLICKED
    | TrackingEvents.CASH_IN_CLICKED
    | TrackingEvents.PROFILE_SCREEN_VISIT
    | TrackingEvents.NAME_SUBMITTED
    | TrackingEvents.DARK_THEME_DISABLED
    | TrackingEvents.DARK_THEME_ENABLED
    | TrackingEvents.RENAME_CASH_BOOK
    | TrackingEvents.SET_SEARCH_FOCUS
    | TrackingEvents.DATE_PICKER_CLICKED
    | TrackingEvents.TIME_PICKER_CLICKED
    | TrackingEvents.BILL_IMAGE_DOWNLOAD_CLICKED
    | TrackingEvents.ATTACH_BILL_IMAGE_CLICKED
    | TrackingEvents.BILL_IMAGE_REMOVED
    | TrackingEvents.INVITE_SHOWN_NEW_USER
    | TrackingEvents.INVITE_DIFFERENT_PHONE_REGISTER_ATTEMPT
    | TrackingEvents.INVITE_SHOWN_ALREADY_USER
    | TrackingEvents.NOTIFICATION_PERMISSION_REQUESTED
    | TrackingEvents.NOTIFICATION_PERMISSION_ALLOWED
    | TrackingEvents.NOTIFICATION_PERMISSION_DISMISSED
    | TrackingEvents.NOTIFICATION_APP_BLOCKED
    | TrackingEvents.DATE_FILTER_CLICKED
    | TrackingEvents.KEYBOARD_SHORTCUTS_SCREEN_VISIT
    | TrackingEvents.APP_UPDATE_AVAILABLE
    | TrackingEvents.APP_UPDATE_CLICKED
    | TrackingEvents.IOS_BANNER_CLICKED
    | TrackingEvents.PHONE_CHANGED
    | TrackingEvents.IMPORT_ENTRIES_SAMPLE_DOWNLOAD_CLICKED
    | TrackingEvents.IMPORT_PARTIES_SAMPLE_DOWNLOAD_CLICKED
    | TrackingEvents.WEB_APP_ELIGIBLE_FOR_INSTALL
    | TrackingEvents.WEB_APP_INSTALL_PROMPT_ACCEPTED
    | TrackingEvents.WEB_APP_INSTALL_PROMPT_REJECTED
    | TrackingEvents.WEB_APP_OPENED_VIA_PWA_INSTALL
    | TrackingEvents.WEB_APP_OPENED_VIA_DESKTOP_INSTALL
    | TrackingEvents.MEMBERS_SCREEN_VIEWED
    | TrackingEvents.VERIFY_EMAIL_CLICKED
    | TrackingEvents.GENERATED_BILL_VIEWED
    | TrackingEvents.GENERATED_BILL_DOWNLOADED
    | TrackingEvents.PARTY_ADDED
    | TrackingEvents.EDIT_PARTY
    | TrackingEvents.PARTY_DELETED
    | TrackingEvents.UPDATE_PARTY_NUMBER
    | TrackingEvents.PARTY_SETTINGS_SCREEN_VIEWED
    | TrackingEvents.IMPORT_PARTY_BUTTON_CLICKED
    | TrackingEvents.ADD_PARTY_BUTTON_CLICKED
    | TrackingEvents.PARTIES_IMPORTED_SUCCESSFULLY
    | TrackingEvents.DUPLICATE_BOOK_CLICKED
    | TrackingEvents.DUPLICATE_BOOK_CREATED
    | TrackingEvents.CHANGE_OWNER_CLICKED
    | TrackingEvents.OWNER_ROLE_CHANGED
    | TrackingEvents.OWNER_ROLE_BANNER_CLICKED
    | TrackingEvents.BUSINESS_ONBOARDING_NEXT_CLICKED
    | TrackingEvents.BUSINESS_ONBOARDING_GET_STARTED_CLICKED
    | TrackingEvents.BUSINESS_SWITCHED
    | TrackingEvents.ADD_EXTERNAL_MEMBER_CLICKED
    | TrackingEvents.BUSINESS_DELETED
    | TrackingEvents.USER_PROFILE_NAME_UPDATED
    | TrackingEvents.BUSINESS_OWNER_CHANGED
    | TrackingEvents.NEED_HELP_IN_BUSINESS_SETUP_CLICKED
    | TrackingEvents.CASHBOOK_PAYMENTS_BANNER_CLICKED
    | TrackingEvents.IMPORT_PARTIES_STARTED
    | TrackingEvents.PASSBOOK_INTRODUCTION_BANNER_CLICKED
    | TrackingEvents.ADD_NEW_CUSTOM_FIELD_CLICKED
): void
export function trackEvent(
  event:
    | TrackingEvents.WEB_APP_INSTALL_NATIVE_ACCEPTED
    | TrackingEvents.WEB_APP_INSTALL_NATIVE_REJECTED,
  properties: { choice: string }
): void
export function trackEvent(
  eventName: TrackingEvents.ADD_NEW_BOOK,
  properties: {
    role: T_BUSINESS_ROLE
    bookName: string
    suggestedTerm: string | undefined
    countBooks: number
    groupBook?: boolean
  }
): void
export function trackEvent(
  eventName: TrackingEvents.LOGOUT,
  properties: {
    type?: "fromAllDevices" | "fromThisDevice"
  }
)
export function trackEvent(
  event: TrackingEvents.APPLY_FILTER,
  properties: {
    type?: "Cash In" | "Cash Out"
    includesOpeningBalance?: boolean
    member?: 0 | 1
    sharedBook: boolean
    from: "filterIcon" | "filerChip" | "pdfPreview" | "csvPreview"
    partiesCount: number
    categoriesCount: number
    paymentModeCount: number
    role: T_BUSINESS_ROLE
    businessId: string
  }
): void
export function trackEvent(
  event: TrackingEvents.DATE_FILTER_APPLIED,
  properties: {
    dateFilter: string
    businessId: string
    role: T_BUSINESS_ROLE
    includesOpeningBalance: boolean
  }
): void
export function trackEvent(
  event: TrackingEvents.CLEAR_ALL_FILTERS_CLICKED,
  properties: {
    numberOfFilters: number
  }
): void
export function trackEvent(
  event: TrackingEvents.CASH_IN_ENTRY_DONE,
  properties: {
    id: string
    type: "cash-in"
    date: string
    amount: number
    remark?: string | null
    billAttached: boolean
    category?: string
    paymentMode?: string
  }
): void
export function trackEvent(
  event: TrackingEvents.CASH_OUT_ENTRY_DONE,
  properties: {
    id: string
    type: "cash-out"
    date: string
    amount: number
    remark?: string | null
    billAttached: boolean
    category?: string
    paymentMode?: string
  }
): void
export function trackEvent(
  event: TrackingEvents.ENTRY_DONE,
  properties: {
    type: "cash-in" | "cash-out"
    sharedBook: boolean
    usingCalculator: boolean
    usingSaveAndNext: boolean
    expression?: string
    categoryUsed: boolean
    paymentModeUsed: boolean
    categoryFromSuggestions?: boolean
    paymentModeFromSuggestions?: boolean
    partyUsed: boolean
    sharable: boolean
    numOfAttachments: number
  }
): void
export function trackEvent(
  event: TrackingEvents.ENTRY_CLICKED,
  properties: {
    sharedBook: boolean
    billAttached: boolean
    role: T_BOOK_MEMBER_ROLE
    isEdited: boolean
    synced: boolean
    entryBySelf: boolean
  }
): void
export function trackEvent(
  event: TrackingEvents.ENTRY_ACTION_CLICKED,
  properties: {
    sharedBook: boolean
    synced: boolean
    entryCount: number
    operation: "move" | "copy" | "opposite"
    from: "singleEntry" | "multiEntry"
  }
): void
export function trackEvent(
  event:
    | TrackingEvents.ENTRY_ACTION_STARTED
    | TrackingEvents.ENTRY_ACTION_COMPLETED,
  properties: {
    destinationBookRole: T_BOOK_MEMBER_ROLE
    sharedBook: boolean
    destinationSharedBook: boolean
    entryBySelf: boolean
    keepOriginalCreator: boolean
    operation: "move" | "copy" | "opposite"
    from: "singleEntry" | "multiEntry"
  }
): void
export function trackEvent(
  event: TrackingEvents.ENTRY_SYNC_STATUS_CLICKED,
  properties: {
    sharedBook: boolean
    billAttached: boolean
    role: T_BOOK_MEMBER_ROLE
    isEdited: boolean
    synced: true
  }
): void
export function trackEvent(
  event: TrackingEvents.BILL_IMAGE_VIEWED,
  properties: {
    duringAttachment: boolean
    type: "pdf" | "image"
  }
): void
export function trackEvent(
  event: TrackingEvents.SHARED_BOOK_LEFT,
  properties: {
    role: T_BOOK_MEMBER_ROLE
  }
): void
export function trackEvent(
  eventName: TrackingEvents.MULTI_ENTRY_CATEGORY_CHANGED,
  properties: {
    sharedBook: boolean
    entryCount: number
    sameCategory?: boolean
    sameCreator: boolean
  }
): void
export function trackEvent(
  eventName: TrackingEvents.MULTI_ENTRY_PAYMENT_MODE_CHANGED,
  properties: {
    sharedBook: boolean
    entryCount: number
    samePaymentMode: boolean
    sameCreator: boolean
  }
): void
export function trackEvent(
  eventName: TrackingEvents.MULTI_ENTRY_PARTY_CHANGED,
  properties: {
    sharedBook: boolean
    entryCount: number
    sameParty: boolean
    sameCreator: boolean
  }
): void
export function trackEvent(
  event:
    | TrackingEvents.GENERATE_PDF_REPORT
    | TrackingEvents.EXCEL_DOWNLOADED
    | TrackingEvents.GENERATE_PDF_ERROR,
  properties: {
    sharedBook: boolean
    member: 0 | 1
    search: boolean
    dateFilter: string
    type: string | undefined
    reportType: "all" | "day_wise" | "parties" | "categories" | "payment_mode"
    from?: "frontend" | "backend"
    errorMessage?: string
    categoriesCount?: number
    paymentModeCount?: number
    bookId: string
  }
): void

export function trackEvent(
  event: TrackingEvents.DELETE_ENTRY,
  properties: {
    sharedBook: boolean
    billAttached?: boolean
    isEdited?: boolean
    synced: boolean
    from: "singleEntry" | "multiEntry"
    entryCount: number
  }
): void
export function trackEvent(
  event: TrackingEvents.EDIT_ENTRY_CLICKED,
  properties: {
    sharedBook: boolean
    billAttached: boolean
    role: T_BOOK_MEMBER_ROLE
    isEdited: boolean
    synced: boolean
    from: "singleEntry" | "list"
  }
): void
export function trackEvent(
  event: TrackingEvents.ENTRY_THREE_DOTS_CLICKED,
  properties: {
    sharedBook: boolean
    billAttached: boolean
    role: T_BOOK_MEMBER_ROLE
    isEdited: boolean
    synced: boolean
  }
): void
export function trackEvent(
  event: TrackingEvents.EDIT_ENTRY,
  properties: {
    sharedBook: boolean
    usingCalculator: boolean
    dateChanged: boolean
    timeChanged: boolean
    remarkChanged: boolean
    amountChanged: boolean
    billChanged: boolean
    entryBySelf: boolean
    categoryChanged: boolean
    paymentModeChanged: boolean
    categoryFromSuggestions?: boolean
    paymentModeFromSuggestions?: boolean
    partyChanged: boolean
  }
): void
export function trackEvent(
  event: TrackingEvents.ADD_MEMBERS_CLICKED,
  properties: {
    countExistingMembers: number
  }
): void
export function trackEvent(
  event: TrackingEvents.ADD_CONTACT_SCREEN_VIEWED,
  properties: {
    countPhone: number
    countCashbookUsers: number
    contactFrom: "contacts" | "manual"
  }
): void
export function trackEvent(
  event: TrackingEvents.HELP_VIEWED,
  properties: {
    id: string
    category?: string
    title: string
  }
): void
export function trackEvent(
  event: TrackingEvents.HELP_CLICKED,
  properties: {
    from: "ThreeDotsIcon" | "Profile"
  }
): void
export function trackEvent(
  event:
    | TrackingEvents.NOTIFICATION_RECEIVED
    | TrackingEvents.NOTIFICATION_CLICKED
    | TrackingEvents.NOTIFICATION_DISMISSED,
  properties: {
    type: "add_entry" | "update_entry" | "add_members" | "others"
  }
): void
export function trackEvent(
  event: TrackingEvents.BOOK_SWITCHED | TrackingEvents.BOOK_SEARCH_CLICKED,
  properties: {
    countBooks: number
  }
): void
export function trackEvent(
  event: TrackingEvents.KEYBOARD_SHORTCUT_USED,
  properties: {
    shortcut: "cash-in" | "cash-out" | "shortcut"
  }
): void
export function trackEvent(
  event: TrackingEvents.MEMBER_ADDED | TrackingEvents.MEMBER_INVITED,
  properties: {
    via: "phone" | "email"
    role: T_BOOK_MEMBER_ROLE
    type: "businessUser" | "addManually"
    allowedBackdatedEntries?: "always" | "never" | "oneday"
    hideBalanceAndReports?: boolean
  }
): void
export function trackEvent(
  event: TrackingEvents.ROLE_CHANGED,
  properties: {
    from: "shareInvite" | "bookDetails"
    fromRole: T_BOOK_MEMBER_ROLE
    toRole: T_BOOK_MEMBER_ROLE
    isInvited: boolean
  }
): void
export function trackEvent(
  event:
    | TrackingEvents.PHONE_CHANGE_ACCOUNT_EXISTS_ERROR
    | TrackingEvents.PHONE_CHANGE_SUBMITTED,
  properties: {
    phone: string
  }
): void
export function trackEvent(
  event:
    | TrackingEvents.IMPORT_ENTRIES_SCREEN_VIEWED
    | TrackingEvents.IMPORT_PARTIES_SCREEN_VIEWED,
  properties: {
    sharedBook: boolean
    role: T_BOOK_MEMBER_ROLE
  }
): void
export function trackEvent(
  event: TrackingEvents.IMPORT_ENTRIES_SUCCEEDED,
  properties: {
    sharedBook: boolean
    role: T_BOOK_MEMBER_ROLE
    totalEntries: number
  }
): void
export function trackEvent(
  event: TrackingEvents.IMPORT_PARTIES_SUCCEEDED,
  properties: {
    sharedBook: boolean
    totalParties: number
    failedParties: number
  }
): void
export function trackEvent(
  event:
    | TrackingEvents.IMPORT_ENTRIES_FILE_SELECTED
    | TrackingEvents.IMPORT_PARTIES_FILE_SELECTED,
  properties: {
    fileType: string
    isValidFile: boolean
  }
): void
export function trackEvent(
  event:
    | TrackingEvents.IMPORT_ENTRIES_FILE_SUBMITTED
    | TrackingEvents.IMPORT_PARTIES_FILE_SUBMITTED,
  properties: {
    headingRowNumber: number
    entriesStartRowNumber: number
    entriesEndRowNumber: number
    totalRows: number
    headers: Array<string>
  }
): void
export function trackEvent(
  event:
    | TrackingEvents.IMPORT_ENTRIES_BATCH_STARTED
    | TrackingEvents.IMPORT_ENTRIES_BATCH_FINISHED,
  properties: {
    totalEntries: number
    totalValidEntries: number
    totalInvalidEntries: number
    dateFormat: string
    timeFormat: string | undefined
    remarkMappedTo?: string
    dateMappedTo?: string
    timeMappedTo?: string
    cashinMappedTo?: string
    cashoutMappedTo?: string
    totalBatches: number
  }
): void
export function trackEvent(
  event:
    | TrackingEvents.IMPORT_ENTRIES_CANCELED
    | TrackingEvents.IMPORT_PARTIES_CANCELED,
  properties: {
    from: string
    hasSelectedFile?: boolean
  }
): void
export function trackEvent(
  event:
    | TrackingEvents.CATEGORY_ADDED
    | TrackingEvents.PARTY_ADDED
    | TrackingEvents.PAYMENT_MODE_ADDED
    | TrackingEvents.CUSTOM_FIELD_ADDED,
  properties: {
    sharedBook: boolean
    from?:
      | "entryFieldSettings"
      | "chooseParty"
      | "chooseCategory"
      | "choosePaymentMode"
      | "importEntries"
      | "importParties"
    via?: "manual"
    fromSuggestions?: boolean
    partyPhoneNumber?: string | null
    partyType?: T_PARTY_TYPES_AVAILABLE
  }
): void
export function trackEvent(
  event: TrackingEvents.EDIT_PARTY,
  properties: {
    nameChanged: boolean
    typeChanged: boolean
    phoneChanged: boolean
    sharedBook: boolean
    entryCount: number
    from?: "entryFieldSettings" | "chooseParty"
  }
): void

export function trackEvent(
  event: TrackingEvents.CUSTOM_FIELD_UPDATED,
  properties: {
    nameChanged: boolean
    fieldRequirementChanged: boolean
    sharedBook: boolean
  }
): void

export function trackEvent(
  event: TrackingEvents.UPDATE_PARTY_NUMBER,
  properties: {
    partyId: string
    phoneNumber: string
    from?: "entryFieldSettings" | "chooseParty"
  }
): void
export function trackEvent(
  event: TrackingEvents.VERIFY_EMAIL_CLICKED,
  properties: {
    email: string
    from: "verifyEmailPopup" | "dashboardVerifyEmailPopup"
    oldEmail: string | null
  }
): void

export function trackEvent(
  event:
    | TrackingEvents.CATEGORY_SETTINGS_SCREEN_VIEWED
    | TrackingEvents.PAYMENT_MODE_SETTINGS_SCREEN_VIEWED,
  properties: {
    from?:
      | "chooseCategory"
      | "choosePaymentMode"
      | "entryFieldSettings"
      | "filters"
  }
): void
export function trackEvent(
  event: TrackingEvents.PARTY_SETTINGS_SCREEN_VIEWED,
  properties: {
    from: "chooseParty" | "entryFieldSettings" | "filters"
    partyCount: number
    sharedBook: boolean
  }
): void
export function trackEvent(
  event: TrackingEvents.CATEGORIES_REORDERED,
  properties: {
    sharedBook: boolean
    categoryCount: number
  }
): void
export function trackEvent(
  event: TrackingEvents.PAYMENT_MODES_REORDERED,
  properties: {
    sharedBook: boolean
    paymentModeCount: number
  }
): void
export function trackEvent(
  event:
    | TrackingEvents.CATEGORY_FIELD_ENABLED
    | TrackingEvents.PAYMENT_MODE_FIELD_ENABLED
    | TrackingEvents.CATEGORY_FIELD_DISABLED
    | TrackingEvents.PAYMENT_MODE_FIELD_DISABLED
    | TrackingEvents.PARTY_FIELD_ENABLED
    | TrackingEvents.PARTY_FIELD_DISABLED,
  properties: {
    sharedBook: boolean
  }
): void
export function trackEvent(
  event: TrackingEvents.IMPORT_CATEGORY_BUTTON_CLICKED,
  properties: {
    sharedBook: boolean
    categoryCount: number
  }
): void

export function trackEvent(
  event: TrackingEvents.INVITATION_CANCELLED,
  properties: {
    invitedFrom: "business"
  }
): void

export function trackEvent(
  event: TrackingEvents.IMPORT_PAYMENT_MODE_BUTTON_CLICKED,
  properties: {
    sharedBook: boolean
    paymentModeCount: number
  }
): void
export function trackEvent(
  event:
    | TrackingEvents.IMPORT_PARTY_BUTTON_CLICKED
    | TrackingEvents.PARTIES_IMPORTED_SUCCESSFULLY,
  properties: {
    sharedBook: boolean
    partyCount: number
  }
): void
export function trackEvent(
  event: TrackingEvents.ADD_PARTY_BUTTON_CLICKED,
  properties: {
    from:
      | "entryFieldSettings"
      | "chooseParty"
      | "chooseCategory"
      | "choosePaymentMode"
  }
): void
export function trackEvent(
  event:
    | TrackingEvents.CATEGORY_DELETED
    | TrackingEvents.PAYMENT_MODE_DELETED
    | TrackingEvents.CATEGORY_RENAMED
    | TrackingEvents.PAYMENT_MODE_RENAMED
    | TrackingEvents.PARTY_DELETED,
  properties: {
    sharedBook: boolean
    entryCount: number
  }
): void

export function trackEvent(
  event: TrackingEvents.CUSTOM_FIELD_DELETED,
  properties: {
    sharedBook: boolean
  }
): void

export function trackEvent(
  event: TrackingEvents.CATEGORIES_IMPORTED_SUCCESSFULLY,
  properties: {
    sharedBook: boolean
    categoryCount: number
  }
): void
export function trackEvent(
  event: TrackingEvents.PAYMENT_MODES_IMPORTED_SUCCESSFULLY,
  properties: {
    sharedBook: boolean
    paymentModeCount: number
  }
): void
export function trackEvent(
  event: TrackingEvents.PHONE_SUBMITTED,
  properties: {
    phone: string
    autoFilled: boolean
  }
): void
export function trackEvent(
  eventName: TrackingEvents.PHONE_NOT_REGISTERED,
  properties: {
    phoneNumber: string
    verificationMethod: "sms" | "whatsapp"
  }
): void
export function trackEvent(
  event: TrackingEvents.EMAIL_SUBMITTED_FOR_LOGIN,
  properties: {
    email: string
    autoFilled: boolean
  }
): void
export function trackEvent(
  event: TrackingEvents.EMAIL_ADDED,
  properties: {
    from: "onboarding" | "profile" | "emailVerificationPopup"
  }
): void
export function trackEvent(
  event: TrackingEvents.LOGIN_OTP_VERIFIED,
  properties: {
    isAutoVerified: boolean
    verificationMethod: VERIFICATION_METHODS
  }
): void
export function trackEvent(
  event: TrackingEvents.OTP_VERIFIED_NEW_USER,
  properties: {
    verificationMethod?: VERIFICATION_METHODS
  }
)
export function trackEvent(
  event: TrackingEvents.MEMBERS_SCREEN_VIEWED,
  properties: {
    from: "bookSettingsIcon"
    role: T_BOOK_MEMBER_ROLE
    bookType: "personal" | "shared"
  }
): void
export function trackEvent(
  event: TrackingEvents.BOOK_DELETION_CANCELLED | TrackingEvents.BOOK_DELETED,
  properties: {
    sharedBook: boolean
    role: T_BOOK_MEMBER_ROLE
  }
): void
export function trackEvent(
  event: TrackingEvents.OTP_RESENT,
  properties: {
    from: "login" | "phoneChange"
    attemptCount: number
    verificationMethod: VERIFICATION_METHODS
  }
): void
export function trackEvent(
  event:
    | TrackingEvents.GENERATED_BILL_VIEWED
    | TrackingEvents.GENERATED_BILL_DOWNLOADED,
  properties: {
    partyName?: string
    partyNumber?: string
    entryId: string
  }
): void
export function trackEvent(
  event: TrackingEvents.DUPLICATE_BOOK_CREATED,
  properties: {
    memberIncluded: boolean
    categoryIncluded: boolean
    paymentModeIncluded: boolean
    partiesIncluded: boolean
    categoriesCount: number
    paymentModesCount: number
    membersCount: number
    partiesCount: number
    role: T_BUSINESS_ROLE
  }
): void
export function trackEvent(
  event: TrackingEvents.PRIVACY_POLICY_VIEWED | TrackingEvents.T_AND_C_VIEWED,
  properties: {
    from: "login" | "profile" | "otp"
  }
): void

export function trackEvent(
  event: TrackingEvents.CHANGE_OWNER_CLICKED,
  properties: {
    from: "bookMembers" | "bookTransactions"
  }
): void

export function trackEvent(
  event: TrackingEvents.OWNER_ROLE_CHANGED,
  properties: {
    fromRole: T_BOOK_MEMBER_ROLE
  }
): void

export function trackEvent(
  event: TrackingEvents.OWNER_ROLE_BANNER_CLICKED,
  properties: {
    role: "admin" | "owner"
  }
): void

//ends here

export function trackEvent(
  event: TrackingEvents.DATA_OPERATOR_PERMISSION_ENABLED,
  properties: {
    allowedBackdatedEntriesEnabled: "always" | "never" | "oneday"
    hideBalanceAndReportsEnabled: boolean
    hideEntriesByOthers: boolean
  }
): void

export function trackEvent(
  event:
    | TrackingEvents.BILL_IMAGE_UPLOAD_STARTED
    | TrackingEvents.BILL_IMAGE_UPLOAD_FINISHED,
  properties: {
    mimeType: string
    fileSizeInMBs: string
  }
)

//BL release
export function trackEvent(
  evenName: TrackingEvents.BUSINESS_CATEGORY_SUBMITTED,
  properties?: {
    categoryId: string
    from?: "onboarding" | "addNewBusiness"
    isSkipped: boolean
  }
): void

export function trackEvent(
  evenName: TrackingEvents.BUSINESS_TYPE_SUBMITTED,
  properties?: {
    typeId: string
    from?: "onboarding" | "addNewBusiness"
    isSkipped: boolean
  }
): void

export function trackEvent(
  evenName: TrackingEvents.NEW_BUSINESS_ADDED,
  properties?: {
    businessName: string
    businessCategory: string
    businessType: string
  }
): void

export function trackEvent(
  evenName: TrackingEvents.NEW_USER_SIGN_UP,
  properties?: {
    isBusinessCategoryAdded: boolean
    isBusinessTypeAdded: boolean
    isBusinessNameAdded: boolean
  }
): void

export function trackEvent(
  eventName:
    | TrackingEvents.BUSINESS_LEFT
    | TrackingEvents.BUSINESS_SETTINGS_VIEWED,
  properties?: {
    role: T_BUSINESS_ROLE
  }
): void

export function trackEvent(
  eventName: TrackingEvents.BUSINESS_PROFILE_VIEWED,
  properties?: {
    role: T_BUSINESS_ROLE
    from: "addOrViewDetails"
    profileStrength: number
  }
): void

export function trackEvent(
  eventName: TrackingEvents.BUSINESS_TEAM_VIEWED,
  properties?: {
    staffSize: number
    numOfPartners: number
    role: T_BUSINESS_ROLE
    from?: "profile"
  }
): void

export function trackEvent(
  eventName: TrackingEvents.BUSINESS_TEAM_MEMBER_PROFILE_VIEWED,
  properties?: {
    isInvited: boolean
    memberRole: T_BUSINESS_ROLE
  }
): void

export function trackEvent(
  eventName: TrackingEvents.ADDED_MEMBER_TO_MULTIPLE_BOOKS,
  properties?: {
    numOfBooks?: number
    numOfBooksAddedIn?: number
    from?: "businessUserProfile"
    isInvited: boolean
  }
): void

export function trackEvent(
  eventName: TrackingEvents.ADD_MEMBER_TO_BOOKS_CLICKED,
  properties?: {
    isInvited: boolean
  }
): void

export function trackEvent(
  eventName: TrackingEvents.BUSINESS_TEAM_MEMBER_ROLE_CHANGED,
  properties?: {
    fromRole: T_BUSINESS_ROLE
    toRole: T_BUSINESS_ROLE
  }
): void

export function trackEvent(
  eventName: TrackingEvents.BUSINESS_TEAM_MEMBER_ADDED_OR_INVITED,
  properties?: {
    role: Omit<T_BUSINESS_ROLE, "owner">
    isInvited: boolean
    from: "manually"
    via: "phone" | "email"
  }
): void

export function trackEvent(
  eventName: TrackingEvents.BUSINESS_TEAM_MEMBER_REMOVED,
  properties?: {
    memberRole: T_BUSINESS_ROLE
  }
): void

export function trackEvent(
  evenName: TrackingEvents.MEMBER_REMOVED,
  properties: {
    from: "bookDetails" | "businessUserProfile"
    isInvited?: boolean
  }
): void

export function trackEvent(
  eventName: TrackingEvents.SOCIAL_AUTHENTICATION_DONE,
  properties: {
    flow: "login" | "signup"
    method: "google" | "apple"
  }
): void

export function trackEvent(
  eventName: TrackingEvents.MEMBER_EMAIL_VERIFICATION_COMPLETED,
  properties?: {
    verificationMethod: "email" | "google"
  }
): void

export function trackEvent(
  eventName:
    | TrackingEvents.INVITATION_SHARED
    | TrackingEvents.INVITATION_RESENT,
  properties: {
    sharedVia: "WhatsApp" | "other"
  }
): void

//BL release ends here

//Payments Release
export function trackEvent(
  eventName: TrackingEvents.PAYMENTS_TAB_SWITCHED,
  properties: {
    currentTab: PAYMENTS_TAB_IDS
    previousTab?: PAYMENTS_TAB_IDS
  }
): void

export function trackEvent(
  eventName: TrackingEvents.PAYMENTS_FILTER_APPLIED,
  properties: {
    from:
      | "businessTransactions"
      | "walletTransactionsExpenses"
      | "digitalAccountTransactions"
      | "businessTransactionsSummaryCard"
    dateFilter?: string
    membersFilter?: boolean
    partiesFilter?: boolean
    statusFilter?:
      | "success"
      | "failed"
      | "pending"
      | "expired"
      | "declined"
      | "refunded"
    billProofFilter?: "attached" | "missing"
  }
): void

export function trackEvent(
  eventName: TrackingEvents.PAYMENTS_REPORT_DOWNLOADED,
  properties: {
    reportType: "pdf" | "csv"
    dateFilterApplied?: boolean
    memberFilterApplied?: boolean
    billProofFilterApplied?: boolean
    transactionStatusFilterApplied?: boolean
  }
): void

export function trackEvent(
  eventName: TrackingEvents.ADD_MONEY_CLICKED,
  properties: {
    entity: "masterWallet" | "memberWallet" | "selfWallet"
    from:
      | "yourWalletScreen"
      | "staffWalletScreen"
      | "dashboard"
      | "masterWalletScreen"
  }
): void

export function trackEvent(
  eventName: TrackingEvents.ISSUE_WALLET_CLICKED,
  properties: {
    existingWalletCount: number
    from: "staffWallets" | "dashboard"
  }
): void

export function trackEvent(
  eventName: TrackingEvents.PAYMENTS_COPY_TO_CASHBOOK_INITIATED,
  properties: {
    from: "multiSelect" | "transactionDetails"
  }
): void

export function trackEvent(
  eventName:
    | TrackingEvents.PAYMENTS_COPY_STARTED
    | TrackingEvents.PAYMENTS_COPY_COMPLETED,
  properties: {
    numberOfSelectedEntries: number
  }
): void

export function trackEvent(
  eventName: TrackingEvents.WALLET_ISSUED,
  properties: {
    memberType: "businessUser" | "cashbookUser" | "newUser"
  }
): void

export function trackEvent(
  eventName: TrackingEvents.LIMITS_CHANGED,
  properties: {
    type: "daily" | "perTransaction"
    usingSlider: boolean
    isStaffWallet: boolean
  }
): void

export function trackEvent(
  eventName: TrackingEvents.SIGNUP | TrackingEvents.CONVERSION,
  properties: {
    send_to: string
  }
): void

//Payments Release ends here

export function trackEvent(event: unknown, properties = undefined) {
  channels.track(event, properties)
}

export function trackPage(
  category?: string,
  name?: string,
  properties?: Record<string, undefined | string | number>
) {
  channels.page(category, name, properties)
}

export function reset() {
  channels.reset()
}

let CACHED_USER: string | null = null

const locationSearchOnLoad =
  typeof window !== undefined ? window.location.search : ""

const trackingUtmParams: Record<string, unknown> = parse(locationSearchOnLoad, {
  ignoreQueryPrefix: true,
})

export function setAnanlyticsIdentity(
  user?: {
    uid: string
    displayName?: string | null
    phoneNumber?: string | null
    email?: string | null
  } | null
) {
  if (!user) {
    CACHED_USER = null
    channels.reset()
  } else {
    const newCacheKey = JSON.stringify(user)
    if (CACHED_USER === newCacheKey) {
      return
    }
    CACHED_USER = newCacheKey
    if (user.phoneNumber) {
      channels.identify(user.uid, {
        ...trackingUtmParams,
        name: user.displayName,
        phoneNumber: user.phoneNumber,
        email: user.email,
      })
    } else {
      channels.identify(user.uid)
    }
  }
}

export function setAnanlyticsIdentityProperties(
  userId: string,
  properties: { viaInviteLink?: boolean; desktopNotificationsAllowed?: boolean }
) {
  channels.identify(userId, { ...trackingUtmParams, ...properties })
}

export function useTrackPage() {
  const { pathname } = useLocation()
  useEffect(() => {
    trackPage()
  }, [pathname])
}
